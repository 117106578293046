@tailwind base;
@tailwind components;
@tailwind utilities;

.food-card {
  width: 100%;
  max-width: 240px; /* Control max-width for better appearance */
  margin: 0 auto; /* Center the card */
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart .marginTop {
  padding-top: 5%;
}

.profile_category_title {
  font-size: 1.5rem; /* equivalent to text-2xl */
  font-weight: 600; /* equivalent to font-semibold */
  letter-spacing: 0.05em; /* equivalent to tracking-wider */
  margin-bottom: 1.25rem; /* equivalent to mb-5 */
}

.form-flex-2 {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.form-flex-2 .ant-form-item {
  flex: 1; /* Makes the form items take equal space */
}


.ant-form-item {
  /* margin-bottom: 0px !important; */
}

.restaurant_profile_button {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 0.6rem 1.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s ease;
}

.restaurant_profile_button:hover {
  background-color: #2563eb;
}

.restaurant_profile_button_disabled {
  background-color: #d1d5db; /* equivalent to bg-gray-300 */
  color: #6b7280; /* equivalent to text-gray-500 */
  cursor: not-allowed; /* equivalent to cursor-not-allowed */
  border: none; /* equivalent to border-none */
  padding: 0.6rem 1.5rem; /* equivalent to px-6 py-3 */
  border-radius: 0.5rem; /* equivalent to rounded-lg */
  display: flex; /* equivalent to flex */
  align-items: center; /* equivalent to items-center */
  justify-content: center; /* equivalent to justify-center */
  transition: background-color 0.2s ease; /* equivalent to transition-colors */
}

.tile-header {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  word-spacing: -1px;
}

.cart .grid-flex h4 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 2px;
}

.cart-details .empty-cart .ant-empty-normal {
  margin: 10% 0;
}

.cart_message {
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.8;
}

.product-showcase {
  padding-top: 5%;
}

.cart-design-body {
  display: grid;
  grid-template-columns: 70fr 30fr;
  grid-gap: 50px;
}

.cart-design-tab {
  border: 1px solid #e1e3e4;
}

.cart-body-detail {
  display: grid;
  grid-template-columns: 90fr 10fr 10fr 10fr;
  grid-gap: 20px;
  border-bottom: 1px solid #e1e3e4;
  padding: 20px 0;
}

.cart-body-detail.no-border {
  border: none;
}

.cart-body-detail .inner-flex {
  display: flex;
  flex: 7 1;
}

.cart-body-detail .small-flex {
  flex: 1 1;
}

.cart-body-detail img {
  height: 6rem;
  width: auto;
  padding-right: 20px;
}

.cart-body-detail p.cart_product_description {
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cart .cart-brief .cart-brief-data {
  border: 1px solid #e1e3e4;
  background: #fff;
  width: 100%;
  max-height: 30rem;
  max-height: max-content;
  overflow: hidden;
  overflow-x: hidden;
  border-radius: 3px;
  padding: 3%;
}

.cart .cart-brief .cart-brief-data ul li,
.give_spacing_to_list li,
.new_list_for_new_checkout li,
ul.first-list.cart-list li {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 2px 0;
  line-height: 1.5;
  font-size: 1.3rem;
}

ul {
  padding-left: 0px !important;
}

.cart .cart-brief .cart-brief-data ul li {
  font-size: 1.4rem;
}

.cart-body-detail .small-flex button {
  background: transparent;
  border: none;
  color: tomato;
  padding: 0 !important;
  font-size: 1.4rem;
}

@media screen and (max-width: 720px) {
  .cart-design-body {
    display: flex;
    flex-direction: column;
  }

  .cart-design-body .cart-design-tab {
    margin-bottom: 20px;
  }

  .cart-design-body .cart-body-detail {
    display: block;
    padding: 20px 0px;
  }

  .cart-design-body .prevOrders.cart-body-detail .inner-flex.new_cart {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-gap: 10px;
    /* align-items: center; */
  }

  .cart-design-body .prevOrders.cart-body-detail .ant-select-selector {
    padding-top: 0% !important;
    margin-top: 0%;
  }

  .cart-design-body .prevOrders.cart-body-detail .cart_product_text {
    align-items: center;
  }

  .prevOrderData .previous_orders ul li {
    line-height: 2.1;
  }

  .cart-design-body .prevOrders.cart-body-detail .mobile-flex-cover {
    display: unset;
  }

  .cart-design-body .prevOrders.cart-body-detail .cart_product_text h4 {
    margin-bottom: 0%;
  }

  .cart-design-body .prevOrders.cart-body-detail .cart_product_text p {
    margin-bottom: 0%;
  }

  .prevOrders.cart-body-detail .inner-flex.new_cart div.cart_product_image {
    width: 100%;
  }

  .cart-design-tab {
    border: none;
  }

  .small-flex {
    display: none;
  }

  .mobile-flex-cover {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-flex {
    display: flex;
    align-items: center;
  }

  .mobile-flex p {
    margin: 0%;
    padding-left: 15px;
    font-weight: 500;
    font-size: 1.3rem;
  }

  .mobile-flex-cover button {
    background: transparent;
    border: none;
    color: tomato;
    outline: none;
    font-size: 11px;
    font-weight: 600;
  }

  .cart-body-detail .inner-flex.new_cart div.cart_product_image img {
    height: 3rem;
    width: auto;
    object-fit: cover;
    object-position: center;
    position: absolute;
    align-self: center;
    padding-right: 0px;
  }

  .cart-body-detail .inner-flex.new_cart div.cart_product_image {
    flex: 1 1;
    margin-bottom: 10px;
    background: #f5f3f1;
    width: 20%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    min-height: 5rem;
    max-height: 5rem;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .cart-body-detail .inner-flex {
    display: flex;
    align-items: center;
    flex: 7 1;
  }

  .cart-body-detail .inner-flex.new_cart {
    align-items: unset;
  }

  .cart .marginTop {
    margin-top: 10%;
  }

  .product-showcase .grid_flex {
    display: flex;
  }

  .cart_product_text {
    flex: 3;
  }

  .inner-flex.new_cart {
    display: block;
  }

  .product-showcase p.product-desc {
    font-size: 1.4rem;
    line-height: 1.8;
    margin-top: 10px;
  }

  .product-showcase .shop_tags {
    display: none;
  }

  .cart-details .empty-cart .ant-empty-normal {
    margin: 40% 0;
  }

  .cart-body-detail p.cart_product_description {
    font-size: 1.3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 8px;
  }

  .ant-collapse
    > .ant-collapse-item.ant-collapse-no-arrow
    > .ant-collapse-header {
    padding-left: 8px;
  }
}
